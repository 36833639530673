import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Requests from '../views/Requests.vue';
import Categories from '../views/Categories.vue';
import Locations from '../views/Locations.vue';
import Translations from '../views/Translations.vue';
import Vouchers from '../views/Vouchers.vue';
import Insurance from '../views/Insurance.vue';
import Matrix from '../views/Matrix.vue';
import Durations from '../views/Durations.vue';
import Sizes from '../views/Sizes.vue';
import ReferrerTypes from '../views/ReferrerTypes.vue';
import StorageReason from '../views/StorageReason.vue';
import Countries from '../views/Countries.vue';
import StoragePurpose from '../views/StoragePurpose.vue';
import CountrySettings from '../views/CountrySettings.vue';
import PaymentProviders from '../views/PaymentAccounts.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/requests',
    name: 'requests',
    component: Requests,
  },
  {
    path: '/categories',
    name: 'categories',
    component: Categories,
  },
  {
    path: '/locations',
    name: 'locations',
    component: Locations,
  },
  {
    path: '/durations',
    name: 'durations',
    component: Durations,
  },
  {
    path: '/sizes',
    name: 'sizes',
    component: Sizes,
  },
  {
    path: '/referrer_types',
    name: 'referrer_types',
    component: ReferrerTypes,
  },
  {
    path: '/storage_reasons',
    name: 'storage_reasons',
    component: StorageReason,
  },
  {
    path: '/translations',
    name: 'translations',
    component: Translations,
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: Vouchers,
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: Insurance,
  },
  {
    path: '/countries',
    name: 'countries',
    component: Countries,
  },
  {
    path: '/storage_purpose',
    name: 'storage_purpose',
    component: StoragePurpose,
  },
  {
    path: '/matrix',
    name: 'matrix',
    component: Matrix,
  },
  {
    path: '/countrysettings',
    name: 'countrysettings',
    component: CountrySettings,
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentProviders,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
