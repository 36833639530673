<template>
  <div>
    <ZebraboxDataTable
      apipath="paymentaccounts"
      :headers="[
        {
          text: 'Name',
          value: `name`,
        },
        {
          text: 'Provider',
          value: 'providername',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
      specialActionIcon="far fa-check-square-o"
    >
      <template v-slot:editForm="{ item }">
        <v-text-field label="Name" v-model="item.name" />
        <v-select
          label="Provider"
          v-model="item.provider"
          :items="paymentproviders"
          item-text="name"
          item-value="id"
        ></v-select>
        <v-text-field label="Customer ID" v-model="item.customerId" />
        <v-text-field label="Username" v-model="item.username" />
        <v-text-field label="Password" type="password" v-model="item.password" />
        <v-select
          label="Alternate config for testing"
          v-model="item.testconfig"
          :items="paymentaccounts"
          item-text="name"
          item-value="id"
          clearable
        ></v-select>
        <v-text-field label="Terminal ID (for testing)" v-model="item.terminalid" />

      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          To make sure
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';

export default {
  name: 'PaymentAccounts',
  components: {
    ZebraboxDataTable,
  },
  data() {
    return {
      editTab: 0,
      paymentproviders: [],
      paymentaccounts: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      checkDialog: false,
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getPaymentProviders().then((data) => {
      this.paymentproviders = data.data;
    });
    BackendService.getDataTableData('paymentaccounts', {}, {})
      .then((data) => {
        this.paymentaccounts = data.data.items;
      });
  },
  methods: {
    newItem(action) {
      action({
        name: '',
        provider: null,
        providername: null,
        customerId: null,
        username: null,
        testconfig: null,
        terminalid: null,
      });
    },
    editItem(item, action) {
      BackendService.savePayment(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this payment account?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deletePayment(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
  },
};
</script>
<style>
.locationPreview {
  max-height: 380px;
  max-width: 380px;
}
tr.disabledLocation {
  background: repeating-linear-gradient(
    45deg,
    #FFF,
    #FFF 10px,
    #EEE 10px,
    #EEE 20px
  );
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody
> tr.disabledLocation:hover
:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: repeating-linear-gradient(
    45deg,
    #DDD,
    #DDD 10px,
    #EEE 10px,
    #EEE 20px
  );
}
</style>
